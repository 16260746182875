
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import DecimalControl from "@/components/controls/base/DecimalControl.vue";
import TextControl from "@/components/controls/base/TextControl.vue";
import CatalogueSelectControl from "@/components/controls//base/CatalogueSelectControl.vue";

export default defineComponent({
  components: {
    CatalogueSelectControl,
    IntegerControl,
    CreateEditDialog,
    DecimalControl,
    TextControl,
  },
  mixins: [mountComponentMixin],
  props: {
    saveModelCallback: { required: true },
  },
  methods: {
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      this.dialog().open(model, editType);
    },
    close() {
      this.dialog().close();
    },
    save() {
      this.dialog().save();
    },
    onShown(model: any) {
      (this as any).mountComponent(
        "components/catalogues/TransportCatalogue/TransportMotorRequisiteSubCatalogueGrid.vue",
        (this as any).requisiteSubGridDomId,
        {
          parentModel: model,
        }
      );
    },
    beforeUnmount() {
      (this as any).unmountComponent((this as any).requisiteSubGridDomId);
    },
  },
  data() {
    return {
      requisiteSubGridDomId: "TransportMotorCreateEditDialog_requisiteSubGrid",
    };
  },
});
